<template>
  <div class="home">
    <social-drawer></social-drawer>
    <div class="extended wfill centered flex">
      <div src="../assets/birthday_banner.png" class="birthday" style="margin: 0 auto -36px auto;"></div>
    </div>
    <div class="hero_section">
      <div class="mauto flex wrap">
        <!-- <div v-if="$mq == 'mobile'" class="section right hero" style="height: 300px"></div> -->
        <div class="mxauto section flex vertical centered">
          <h5 class="center fs14 desktop-fs20">The Ethos Journey</h5>
          <h1 class="bold center mt4 center">Engage - Empower - Educate</h1>
          <p v-html="$bus.siteData.heroDescription" style="margin-top: 16px; max-width: 600px" class="center mxauto">
            <!-- Ethos strives to equip students to be agents of change, to be
            leaders, thinkers, and to contribute towards building a better
            future. Our main objective is to be the wind beneath the wings of
            young minds that are to shape our built environment in the years to
            come. -->
          </p>
          <div class="mxauto mt24">
            <button
              :style="{ width: $mq == 'mobile' ? '100%' : '' }"
              :class="[$mq != 'mobile' && 'mx12']"
              class="my2 inverted black"
              @click="$router.push('/auth')"
            >
              Sign Up
            </button>
            <button
              :style="{ width: $mq == 'mobile' ? '100%' : '' }"
              :class="[$mq != 'mobile' && 'mx12']"
              class="my4 black"
              @click="$router.push('/auth')"
            >
              Login
            </button>
          </div>
        </div>
        <!-- <div v-if="$mq != 'mobile'" class="section right hero" style="height: 60vh"></div> -->
      </div>
      <div
        class="hero_section_image"
        style="width: calc(100% + 120px); margin-left: -60px;"
      >
        <img
          src="../assets/ethos_hero_final_final.jpg"
          width="calc(100% + 120px)"
          class="extended hero_bottom"
        />
      </div>
    </div>
    <div
      v-if="$bus.siteData.verticals"
      class="flex wrap py12"
      style="width: calc(100% + 100px); margin-left: -50px;"
    >
      <hero-section
        class="ml28 hero_desc"
        :style="{
          width: $mq == 'mobile' ? 'calc(100% - 28px)' : 'calc(25% - 28px)',
          borderRight: $mq == 'mobile' ? '' : '1px dashed #eee'
        }"
        style="border-right: 1px dashed #eee;"
        v-for="v in $bus.siteData.verticals"
        :vertical="v"
        :key="v.id"
      >
      </hero-section>
    </div>
    <div class="wide extended knowledge_is_power" style="margin-top: -20px;">
      <div v-if="$mq != 'mobile'" class="section left flex">
        <div
          class="mauto photo1"
          v-if="$bus.siteData.section2photo1"
          :style="{
            background:
              'url(' +
              $bus.siteData.section2photo1.url +
              ') center / cover no-repeat'
          }"
        ></div>
        <div
          class="photo2"
          v-if="$bus.siteData.section2photo2"
          :style="{
            background:
              'url(' +
              $bus.siteData.section2photo2.url +
              ') center / cover no-repeat'
          }"
        ></div>
      </div>
      <div class="section right">
        <h2 class="left black fs32" :class="[$mq == 'mobile' ? 'center' : 'left']">
          {{ $bus.siteData.section2Header || "Knowledge Is Power" }}
        </h2>
        <p v-html="$bus.siteData.section2Description" class="black left" style="margin: 20px 0px">
          <!-- Architects today are working with a lot of constraints. Along with
          being designers, they are expected to assume the roles of managers,
          entrepreneurs, documenters, facilitators, critics, theorists,
          collectors, archivists, and historians. An increasingly complex world
          requires architects who can contribute to larger, more
          interdisciplinary teams. The world calls for architects skilled in
          available technologies, capable of linking and sharing information in
          a multimedia environment and to become leaders who work towards
          shaping our futures. -->
        </p>
        <div class="spaced flex">
          <stat
            v-for="s in $bus.siteData.stats"
            :key="s.name"
            :stat="s.stat"
            :title="s.name"
          />
        </div>
      </div>
    </div>
    <whatsnew></whatsnew>
    <!-- div class="extended mt0 flex wrap founders_parent" style="margin-bottom: 40px;">
      <div v-if="$mq == 'mobile'" style="height: 300px;" class="section right founders"></div>
      <div class="section left">
        <h1 class="bold mt64 fw600">From the Founder's Desk</h1>
        <p class="mt16" v-html="$bus.siteData.fromTheFoundersDesk">
        </p>
        <button class="mt40 black" @click="$bus.redirect('https://www.linkedin.com/in/gita-balakrishnan-161aa814/?originalSubdomain=in')">Connect on Linkedin</button>
      </div>
      <div v-if="$mq != 'mobile'" class="section right founders"></div>
    </div -->
    <!-- div class="extended partners_section" style="margin-top: 40px;">
      <partners class="px0 wfill"></partners>
    </div -->
    <div class="extended flex vertical mt80 mb80 video_parent">
      <h4 class="bold mt12 fw600 center" style="margin-bottom: 28px;">
        Watch us in action
      </h4>
      <iframe
        style="width: 80%; margin-left: 10%; margin-top: 20px"
        height="400"
        :src="$bus.siteData.videoUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <!-- div style="padding-bottom: 200px;">
      <h4 style="text-align: center;">Team Ethos</h4>
      <p style="text-align: center;">{{$bus.siteData.teamDescription}}</p>
      <div class="team extended">
        <div v-for="t in $bus.siteData.team" :key="t.name" class="team_member">
          <div class="photo" v-if="t.profilePicture" :style="{
            background: 'url(' + t.profilePicture.url + ') center / cover'
          }"></div>
          <h6 class="name bold">{{t.name}}</h6>
          <p class="designation">{{t.designation}}</p>
          <i v-if="t.linkedInUrl" class="linkedin icon" @click="$bus.redirect(t.linkedInUrl)"></i>
        </div>
      </div>
    </div -->
    <div
      class="extended"
      style="background: #f5f5f5; height: 10px; margin-top: 40px;"
    ></div>
    <testimonials style="margin-top: 0px;" class="extended"></testimonials>
    <!-- <div class="flex vertical mt80">
      <h2 class="bold mt12 fw600 center">An idea is mightier than the sword</h2>
      <p class="mt12 w500 mxauto center">
        Find out what some of our past event winners 
        have to say about their experience of being associated with Ethos.
      </p>
    </div> -->
    <div
      @click="
        $bus.redirect('https://wa.me/message/7KVR3WRNG7P2K1', false, true)
      "
      class="whatsapp_logo"
    ></div>
  </div>
</template>

<script>
import moment from "moment";
import whatsnew from "../components/whatsnew";
import heroSection from "../components/hero-section";
import testimonials from "../components/testimonials";
import socialDrawer from "../components/social-drawer";
import stat from "../components/stat";
export default {
  name: "Home",
  components: {
    socialDrawer,
    stat,
    testimonials,
    heroSection,
    whatsnew
  },
  data() {
    return {
      images: [{ url: "" }, { url: "" }, { url: "" }],
      sectionHeader: "",
      sectionDescription: "",
      stats: ["A", "B", "C", "D"]
    }
  },
  computed: {
    isBirthday: function() {
      return moment().format("DD MMM") == "29 Sep";
    }
  }
};
</script>

<style lang="scss">
.hero_section {
  height: calc(100vh - 64px);
  position: relative;
  display: flex;
  flex-direction: column;
}
.hero_section_image {
  bottom: 0px;
}
.birthday {
  background: url("../assets/birthday_banner.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 250px;
  width: 900px;
}
.banner {
  width: 100%;
  height: 32px;
  background: #b98744;
  position: absolute;
  top: 8px;
  left: 0px;
  display: flex;
  justify-content: center;
}

.banner span > span {
  animation: 1s color-change;
}

@for $i from 1 through 20 {
  .banner span > span:nth-child(#{$i}) {
    animation-delay: $i * 0.1s;
  }
}

@keyframes color-change {
  0% {
    color: #e9b774;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #e9b774;
  }
}

.home {
  width: 100%;
  position: relative;
  padding: 0px 100px;
  overflow: hidden;
}

.extended {
  width: calc(100% + 200px);
  margin-left: -100px;
}

@media (max-width: 800px) {
  .home {
    padding: 0px 60px;
  }
  .extended {
    width: calc(100% + 120px);
    margin-left: -60px;
  }
  .extended.wide {
    width: calc(100% + 240px);
    margin-left: -120px;
  }
}

.hero_bottom {
  background: url("../assets/ethos_hero_new.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  width: calc(100% + 120px);
  margin-left: -60px;
}

@media (max-width: 800px) {
  .hero_bottom {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
}

.hero_bottom_cover {
  background: #f5f5f5;
  height: 20px;
  margin-top: -5px;
  margin-bottom: -10px;
}

// .hero {
//   background: url('../assets/ethos_hero.svg');
//   background-size: contain;
//   background-position: center;
//   background-repeat: no-repeat;
// }

.knowledge_is_power {
  position: relative;
  display: flex;
  padding: 40px 100px;
  background: url("../assets/knowledge_bg.svg"), #f5f5f5;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.knowledge_is_power .photo1 {
  margin: 0 auto auto 0;
  width: 90%;
  height: 70%;
  border-radius: 20px;
}

.knowledge_is_power .photo2 {
  margin: auto;
  width: 55%;
  height: 50%;
  top: 50%;
  left: 40%;
  position: absolute;
  border-radius: 20px;
}

.founders {
  background: url("../assets/founders_desk.svg");
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat;
}

.founders_parent {
  background: url("../assets/founders_bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 100px;
}

@media (max-width: 600px) {
  .knowledge_is_power,
  .founders_parent {
    padding: 40px;
  }
}

.ethos_of_learning {
  background: url("../assets/ethos_of_learning.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.video_parent {
  // background: url('../assets/video_bg.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.team {
  // background: url('../assets/team_bg_top.svg'), url('../assets/team_bg_bottom.svg');
  background-size: contain, contain;
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  padding-bottom: 80px;
}

video {
  width: 80%;
  height: 400px;
}
.hero {
  display: flex;
  width: 100%;
}
.section.left {
  width: 50%;
  position: relative;
}
.section.right {
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 20px;
  width: 50%;
}

.mobile-center {
  text-align: initial;
}

@media (max-width: 800px) {
  .section.left {
    width: 100%;
    position: relative;
  }
  .section.right {
    width: 100%;
    position: relative;
  }
  .mobile-center {
    text-align: center;
  }
}

.home .section.right h1,
.home .section.right p {
  margin: 8px;
}
.home .image {
  position: absolute;
  bottom: 0px;
  left: -8px;
}
.home .image:first-child {
  width: 400px;
  height: 400px;
  background: #ccc;
}
.home .image:nth-child(2) {
  width: 250px;
  height: 250px;
  background: #ddd;
  left: 267px;
}
.home .image:nth-child(3) {
  width: 160px;
  height: 160px;
  background: #eee;
  left: 437px;
}

.team {
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
  top: 50px;
  z-index: -1;
}

.team_member {
  width: 20%;
  margin: 20px 0px;
}

@media (max-width: 1500px) {
  .team_member {
    width: 25%;
  }
}

@media (max-width: 1000px) {
  .team_member {
    width: 33%;
  }
}

@media (max-width: 600px) {
  .team_member {
    width: 50%;
  }
}

.team_member .photo {
  width: 110px;
  height: 110px;
  border-radius: 100px;
  background: #eee;
  margin: 4px auto;
}

.team_member .name,
.team_member .designation {
  text-align: center;
}
.team_member .name {
  color: #333;
  margin-top: 8px;
  font-size: 16px;
  letter-spacing: 0em;
  font-weight: bold;
  text-transform: none;
}

.team_member .designation {
  font-size: 16px;
  color: #999;
}

.team_member i {
  width: 100%;
  color: #865a18;
  font-size: 21px;
  margin-top: 4px;
}
.whatsapp_logo {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  display: flex;
  background: url("../assets/whatsapp_logo.png");
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
