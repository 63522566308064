var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('social-drawer'),_vm._m(0),_c('div',{staticClass:"hero_section"},[_c('div',{staticClass:"mauto flex wrap"},[_c('div',{staticClass:"mxauto section flex vertical centered"},[_c('h5',{staticClass:"center fs14 desktop-fs20"},[_vm._v("The Ethos Journey")]),_c('h1',{staticClass:"bold center mt4 center"},[_vm._v("Engage - Empower - Educate")]),_c('p',{staticClass:"center mxauto",staticStyle:{"margin-top":"16px","max-width":"600px"},domProps:{"innerHTML":_vm._s(_vm.$bus.siteData.heroDescription)}}),_c('div',{staticClass:"mxauto mt24"},[_c('button',{staticClass:"my2 inverted black",class:[_vm.$mq != 'mobile' && 'mx12'],style:({ width: _vm.$mq == 'mobile' ? '100%' : '' }),on:{"click":function($event){return _vm.$router.push('/auth')}}},[_vm._v(" Sign Up ")]),_c('button',{staticClass:"my4 black",class:[_vm.$mq != 'mobile' && 'mx12'],style:({ width: _vm.$mq == 'mobile' ? '100%' : '' }),on:{"click":function($event){return _vm.$router.push('/auth')}}},[_vm._v(" Login ")])])])]),_vm._m(1)]),(_vm.$bus.siteData.verticals)?_c('div',{staticClass:"flex wrap py12",staticStyle:{"width":"calc(100% + 100px)","margin-left":"-50px"}},_vm._l((_vm.$bus.siteData.verticals),function(v){return _c('hero-section',{key:v.id,staticClass:"ml28 hero_desc",staticStyle:{"border-right":"1px dashed #eee"},style:({
        width: _vm.$mq == 'mobile' ? 'calc(100% - 28px)' : 'calc(25% - 28px)',
        borderRight: _vm.$mq == 'mobile' ? '' : '1px dashed #eee'
      }),attrs:{"vertical":v}})}),1):_vm._e(),_c('div',{staticClass:"wide extended knowledge_is_power",staticStyle:{"margin-top":"-20px"}},[(_vm.$mq != 'mobile')?_c('div',{staticClass:"section left flex"},[(_vm.$bus.siteData.section2photo1)?_c('div',{staticClass:"mauto photo1",style:({
          background:
            'url(' +
            _vm.$bus.siteData.section2photo1.url +
            ') center / cover no-repeat'
        })}):_vm._e(),(_vm.$bus.siteData.section2photo2)?_c('div',{staticClass:"photo2",style:({
          background:
            'url(' +
            _vm.$bus.siteData.section2photo2.url +
            ') center / cover no-repeat'
        })}):_vm._e()]):_vm._e(),_c('div',{staticClass:"section right"},[_c('h2',{staticClass:"left black fs32",class:[_vm.$mq == 'mobile' ? 'center' : 'left']},[_vm._v(" "+_vm._s(_vm.$bus.siteData.section2Header || "Knowledge Is Power")+" ")]),_c('p',{staticClass:"black left",staticStyle:{"margin":"20px 0px"},domProps:{"innerHTML":_vm._s(_vm.$bus.siteData.section2Description)}}),_c('div',{staticClass:"spaced flex"},_vm._l((_vm.$bus.siteData.stats),function(s){return _c('stat',{key:s.name,attrs:{"stat":s.stat,"title":s.name}})}),1)])]),_c('whatsnew'),_c('div',{staticClass:"extended flex vertical mt80 mb80 video_parent"},[_c('h4',{staticClass:"bold mt12 fw600 center",staticStyle:{"margin-bottom":"28px"}},[_vm._v(" Watch us in action ")]),_c('iframe',{staticStyle:{"width":"80%","margin-left":"10%","margin-top":"20px"},attrs:{"height":"400","src":_vm.$bus.siteData.videoUrl,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),_c('div',{staticClass:"extended",staticStyle:{"background":"#f5f5f5","height":"10px","margin-top":"40px"}}),_c('testimonials',{staticClass:"extended",staticStyle:{"margin-top":"0px"}}),_c('div',{staticClass:"whatsapp_logo",on:{"click":function($event){return _vm.$bus.redirect('https://wa.me/message/7KVR3WRNG7P2K1', false, true)}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"extended wfill centered flex"},[_c('div',{staticClass:"birthday",staticStyle:{"margin":"0 auto -36px auto"},attrs:{"src":"../assets/birthday_banner.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero_section_image",staticStyle:{"width":"calc(100% + 120px)","margin-left":"-60px"}},[_c('img',{staticClass:"extended hero_bottom",attrs:{"src":require("../assets/ethos_hero_final_final.jpg"),"width":"calc(100% + 120px)"}})])
}]

export { render, staticRenderFns }